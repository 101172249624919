:root {
  --background-color: hsl(167, 53%, 97%);
  --text-color: hsl(177, 55%, 26%);
  --border-color: hsla(177, 55%, 26%, 0.2);

  --transition-duration: 200ms;
}

@font-face {
  font-display: swap;
  font-family: RoobertPRO;
  font-weight: 400;
  src: url(./fonts/RoobertPRO-Regular.woff2) format("woff2"),
    url(./fonts/RoobertPRO-Regular.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: RoobertPRO;
  font-weight: 600;
  src: url(./fonts/RoobertPRO-SemiBold.woff2) format("woff2"),
    url(./fonts/RoobertPRO-SemiBold.woff) format("woff");
}

body {
  font-size: 16px;
  line-height: 1.5;
  font-family: "RoobertPRO", system-ui, sans-serif;
  color: var(--text-color);
  background: var(--background-color);
  -webkit-font-smoothing: antialiased;
  padding: 1rem;
  max-width: 36rem;
}

h1,
h2,
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h1,
h2 {
  font-weight: 600;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8rem;
}

* + h2 {
  margin-top: 2rem;
}

a {
  color: inherit;
  padding: 4px 0;
  text-decoration: none;
  border-bottom: 1px solid var(--border-color);
  transition: border-color ease var(--transition-duration);
}

a:hover {
  border-color: var(--text-color);
}
