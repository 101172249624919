:root {
  --background-color: #f3fbfa;
  --text-color: #1e6763;
  --border-color: #1e676333;
  --transition-duration: .2s;
}

@font-face {
  font-display: swap;
  font-family: RoobertPRO;
  font-weight: 400;
  src: url("RoobertPRO-Regular.3c55e112.woff2") format("woff2"), url("RoobertPRO-Regular.eb9100e1.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: RoobertPRO;
  font-weight: 600;
  src: url("RoobertPRO-SemiBold.6e5dedfc.woff2") format("woff2"), url("RoobertPRO-SemiBold.518d7a5b.woff") format("woff");
}

body {
  color: var(--text-color);
  background: var(--background-color);
  -webkit-font-smoothing: antialiased;
  max-width: 36rem;
  padding: 1rem;
  font-family: RoobertPRO, system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

h1, h2, p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h1, h2 {
  font-weight: 600;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .8rem;
}

* + h2 {
  margin-top: 2rem;
}

a {
  color: inherit;
  border-bottom: 1px solid var(--border-color);
  transition: border-color ease var(--transition-duration);
  padding: 4px 0;
  text-decoration: none;
}

a:hover {
  border-color: var(--text-color);
}

/*# sourceMappingURL=index.4b6aa868.css.map */
